function Competitors () {

    return(

        <div className="Competitors">

            <section className="bg-black py-5 text-white">
                <div className="container-fluid p-5">
                    <div className="row">
                        <div className="col-12 col-lg-6 text-start align-self-center">
                            <span>POR QUE DEEL</span>
                            <h1>En que se diferencia Deel vs. otros proveedores</h1>
                            <p className="my-4 mb-5">Deel tiene más de 100 entidades, más de 20 integraciones, más de 15 métodos de pago, SIRH, soporte 24/7 y más para ayudarte a escalar globalmente más rápido y con un compliance global inigualable.</p>
                            <a href="" className="link-white p-3 fw-semibold">Solicita un demo</a>
                        </div>
                        <div className="col-12 col-lg-6">
                            <img src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/hero-competitors.webp" alt="" className="img-fluid" />
                        </div>
                    </div>                    
                </div>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-12 col-lg-6 offset-lg-3">
                            <h4>Empresas de todos los tamaños eligen Deel para sus necesidades de RR.HH.</h4>    
                        </div>
                        <div className="col-12 my-5">
                            <div className="row list-sponsors">
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/Deel/Assets/images/2023/Competitors%20(Rippling)%20Images/1.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/Klarna.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/shopify.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/Forever_21_logo%201.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/intercom-logo-white.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/Revolut-Logo.white%20(1)%202.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/Reddit.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/Calvin%20Klein.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/Zapier.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/hermes-1%202.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/Brut--logo.svg" alt="" /></div>
                                <div className="sponsor col-12"><img className='img-fluid' src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/Logo%20Ubisoft%20SVG.svg" alt="" /></div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row">
                    <div className="col-12">
                            <div className="row p-3">    
                                <div className="col-12 col-md-8 offset-md-2 mb-5">
                                    <h3>Por qué los equipos prefieren Deel para RR.HH. y nómina globales</h3>
                                </div>
                                <nav>
                                    <div class="nav mini-nav nav-tabs justify-content-center border-0" id="nav-tab" role="tablist">
                                        <button class="nav-link my-1 border-bottom me-lg-5 fw-medium active" id="nav-contratacion-tab" data-bs-toggle="tab" data-bs-target="#nav-contratacion" type="button" role="tab" aria-controls="nav-contratacion" aria-selected="true">Razon #1</button>
                                        <button class="nav-link my-1 border-bottom me-lg-5 fw-medium" id="nav-global-tab" data-bs-toggle="tab" data-bs-target="#nav-global" type="button" role="tab" aria-controls="nav-global" aria-selected="false">Razon #2</button>
                                        <button class="nav-link my-1 border-bottom me-lg-5 fw-medium" id="nav-compliance-tab" data-bs-toggle="tab" data-bs-target="#nav-compliance" type="button" role="tab" aria-controls="nav-compliance" aria-selected="false">Razon #3</button>
                                        <button class="nav-link my-1 border-bottom me-lg-5 fw-medium" id="nav-localizacion-tab" data-bs-toggle="tab" data-bs-target="#nav-localizacion" type="button" role="tab" aria-controls="nav-localizacion" aria-selected="false">Razon #4</button>
                                        <button class="nav-link my-1 fw-medium border-bottom" id="nav-fusiones-tab" data-bs-toggle="tab" data-bs-target="#nav-fusiones" type="button" role="tab" aria-controls="nav-fusiones" aria-selected="false">Razon #5</button>
                                    </div>
                                </nav>             
                            </div>                            
                        </div>
                    </div>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-contratacion" role="tabpanel" aria-labelledby="nav-contratacion-tab" tabindex="0">
                            <div className="row py-5 my-5">
                                <div className="col-12 col-xxl-6 align-self-center my-3">
                                    <div className="card border-0 rounded-4 p-0 text-start">
                                        <h3 className='fw-medium fs-1'>Crece y escala hacia todos lados</h3>
                                        <p className="my-4 color-grey">Deel es la única plataforma de RR.HH. pionera en el mundo diseñada para escalar con tu empresa, sin importar la fase de crecimiento. Tenemos la mejor cobertura de entidades propias del mundo y somos la única plataforma que apoya a cualquier tipo de trabajador. Además, tenemos incorporado sistemas SIRH, de nómina, PEO, visas y mucho más, y nuestra solución plug-and-play es personalizable para adaptarse a las necesidades del equipo.</p>
                                        <div className="row">
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Contrata y paga a todos con los servicios internos de Deel para Global Payroll, US Payroll, PEO, EOR, Contractor Management e Immigration.</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Gestiona y desarrolla a toda tu fuerza laboral con Deel HR y Deel Engage</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Obtén los servicios expertos de Deel para la creación de entidades, la expansión a nuevos mercados, la consultoría de equity y más</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Accede a herramientas de apoyo, como información sobre salarios, guías de empleo, beneficios, y más</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Recibe alertas sobre nuevas normativas y posibles infracciones en más de 100 países con nuestro Compliance Hub</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xxl-6 align-self-center my-3">
                                    <img src="https://www.deel.com/es/hs-fs/hubfs/comp-scale-up.webp?width=1428&height=1440&name=comp-scale-up.webp" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-global" role="tabpanel" aria-labelledby="nav-global-tab" tabindex="0">
                            <div className="row py-5 my-5">
                                <div className="col-12 col-xxl-6 align-self-center my-3">
                                    <div className="card border-0 rounded-4 p-0 text-start">
                                        <h3 className='fw-medium fs-1'>Haz crecer tu equipo con todo lo que necesitas</h3>
                                        <p className="my-4 color-grey">Deel es la única plataforma con configuraciones totalmente personalizables para la incorporación, nómina, cambios de contrato, permisos, opciones de pago y más. Es fácil para los trabajadores y tiene todo lo que necesitas para ayudar a tus equipos a trabajar de forma más rápida, mejor e inteligente en todo el mundo.</p>
                                        <div className="row">
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Verificaciones de antecedentes integrada, dotación de equipo, pases WeWork, gestión del rendimiento y del aprendizaje, desarrollo profesional, plugins de HR Slack.</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Más de 15 métodos de pago y retiro, como PayPal, Payoneer, Wise, Coinbase, una tarjeta de débito global y más</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Los trabajadores independientes pueden ponerse en contacto con asesores fiscales locales de confianza dentro de la plataforma, quienes pueden explicarles las obligaciones fiscales e incluso hacer su declaración de impuestos</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Recopilación de documentos que cumplen con las leyes locales para empleados y trabajadores independientes de EOR en más de 100 países, revisada y actualizada constantemente</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Ventajas para optimizar el trabajo y el estilo de vida de tu equipo</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xxl-6 align-self-center my-3">
                                    <img src="https://www.deel.com/es/hs-fs/hubfs/grow-your-team.jpg?width=1428&height=1440&name=grow-your-team.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-compliance" role="tabpanel" aria-labelledby="nav-compliance-tab" tabindex="0">
                            <div className="row py-5 my-5">
                                <div className="col-12 col-xxl-6 align-self-center my-3">
                                    <div className="card border-0 rounded-4 p-0 text-start">
                                        <h3 className='fw-medium fs-1'>Confía en el soporte interno multicanal 24/7</h3>
                                        <p className="my-4 color-grey">Deel ofrece el soporte interno más rápido del mercado. Otros proveedores trabajan con partners externos, creando un nivel de servicio incongruente a nivel internacional. Además, la incorporación suele ser de autoservicio o gestionada por correo electrónico, lo que ralentiza el proceso.</p>
                                        <div className="row">
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">CSM interno dedicado y soporte de incorporación en tu zona horaria e idioma</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Asistencia multicanal: chat en la app 24/7 para ti y tus equipos, email, WhatsApp, teléfono, solicitudes de devolución de llamadas, videollamadas y Slack para empresas</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Equipo de experiencia de RR.HH. en el país para empleados de EOR</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Asistente de AI para obtener información instantánea sobre RR.HH. y el cumplimiento legal a nivel mundial en más de 100 países, en la app e integrado en Slack</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xxl-6 align-self-center my-3">
                                    <img src="https://www.deel.com/es/hs-fs/hubfs/comp-support-1.webp?width=1430&height=1440&name=comp-support-1.webp" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-localizacion" role="tabpanel" aria-labelledby="nav-localizacion-tab" tabindex="0">
                            <div className="row py-5 my-5">
                                <div className="col-12 col-xxl-6 align-self-center my-3">
                                    <div className="card border-0 rounded-4 p-0 text-start">
                                        <h3 className='fw-medium fs-1'>Contrata realmente donde quieras</h3>
                                        <p className="my-4 color-grey">Deel tiene una cobertura líder en la industria, utilizando nuestra propia infraestructura para garantizar un servicio de calidad y cumplimiento legal. La mayoría de los competidores tienen menos cobertura o trabajan con socios para la configuración de sus entidades, lo que significa que no tienen el control de la experiencia de principio a fin.</p>
                                        <div className="row">
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Cuenta con una red de entidades en más de 100 países
                                                        </h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Nómina y profesionales operados internamente en todos los países en los que prestan servicios para garantizar el apoyo directo y mantener estándares uniformes</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Servicios de inmigración internos en más de 40 países, como Estados Unidos, Canadá y Emiratos Árabes Unidos</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Más de 50 motores de nómina propios</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xxl-6 align-self-center my-3">
                                    <img src="https://www.deel.com/es/hs-fs/hubfs/comp-hire-wherever-1.webp?width=1428&height=1440&name=comp-hire-wherever-1.webp" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-fusiones" role="tabpanel" aria-labelledby="nav-fusiones-tab" tabindex="0">
                            <div className="row py-5 my-5">
                                <div className="col-12 col-xxl-6 align-self-center my-3">
                                    <div className="card border-0 rounded-4 p-0 text-start">
                                        <h3 className='fw-medium fs-1'>Automatiza todo tu flujo de trabajo</h3>
                                        <p className="my-4 color-grey">Deel tiene todo lo que necesitas para trabajar de manera eficiente a escala global. Somos la única plataforma con configuraciones totalmente personalizables para la incorporación, la nómina, los cambios de contrato, más de 14 opciones de pago y mucho más.</p>
                                        <div className="row">
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">La fecha de cierre de la nómina es el 20 de cada mes</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Diferencia en la nómina: te ayudamos a identificar, dar contexto y comunicar las razones de los cambios salariales cada mes</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">Informes globales avanzados y personalizables (desde la nómina y el crecimiento del personal hasta D&I y PTO)</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 border-bottom py-3">
                                                <div className="row">
                                                    <div className="col-7 col-lg-7">
                                                        <h6 className="fw-bold color-grey">API y más de 70 integraciones, con la opción de conectar datos de otros proveedores locales de nómina</h6>
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/tick.svg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-2 col-lg-2 text-end">
                                                        <img src="https://www.deel.com/hubfs/Deel/competitor-page/cross-red.svg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xxl-6 align-self-center my-3">
                                    <img src="https://www.deel.com/es/hs-fs/hubfs/comp-automate-more.webp?width=1428&height=1440&name=comp-automate-more.webp" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-12 col-lg-6 my-3 align-self-center">
                            <img src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/deel-vs-other-competitors.svg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-12 col-lg-6 my-3 align-self-center text-start p-lg-5">
                            <h3>Nomina mas rapida y flexible</h3>
                            <p className="color-grey fw-medium my-4">La mayoria de las plataformas exigen presentar la nomina entre el 4 y el 10 de cada mes en la mayoria de los paises, lo que deja menos tiempo para hacer los ajustes y se traduce en comisiones adicionales por los pagos fuera del ciclo. Las fechas de corte lideres en la industria de Deel te brindan una flexibilidad inigualable.</p>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-12 col-lg-6 my-3 align-self-center text-start p-lg-5">
                            <h3>Ahorra con un sistema de RR.HH. para todo tu equipo</h3>
                            <p className="color-grey fw-medium my-4">Entendemos los desafíos de gestionar una fuerza laboral global en sistemas locales inconexos. Ofrecemos nuestro SIRH completo de forma gratuita, lo que garantiza que tu proceso con nuestra EOR y Global Payroll sea perfecto.</p>
                            <div className="d-flex">
                                <a href="" className="link-black p-3 me-3">Mas sobre Deel HR</a>
                                <a href="" className="link-white p-3">Programa una demostracion</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 my-3 align-self-center">
                            <img src="https://19498232.fs1.hubspotusercontent-na1.net/hubfs/19498232/comp-deel-hr.jpg" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )

}

export default Competitors