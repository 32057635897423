
function Pricing () {
    return (
        <div className="pricing">

            <section className="bg-light-blue py-5">
                <div className="container">
                    <div className="row text-center justify-content-center">
                        <div className="col-12 col-md-7">
                            <span>PRECIOS</span>
                            <h3 className="mb-4">Precios transparentes y justos que crecen en tu negocio</h3>
                            <p className='mb-4'>Ya sea que quieras contratar a un trabajador independiente en Reino Unido, 20 colaboradores en Brasil o gestionar la nomina donde sea que tengas entidades, puedes hacerlo todo con Deel.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="d-flex align-items-center my-3 mb-5">
                        <img src="https://deel-website-media-prod.s3.amazonaws.com/Avatar_3_1ec0a6163e.png" alt="" className="img-fluid me-3 icon-small" />
                        <h3>Contratacion y nominas</h3>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 my-3">
                            <div className="card text-start p-4 card-border-blue rounded-4">
                                <div className="card-head">
                                    <h4>Deel Contractor</h4>
                                    <p>¿Te preocupa la clasificacion erronea de los trabajadores independientes? Permitenos manejar el cumplimiento, los pagos y mas en 150 paises.</p>
                                    <p className="fw-semibold mb-0">A partir de</p>
                                    <div className="d-flex align-items-center">
                                        <h3 className="me-2">$49</h3>
                                        <p className="mb-0 fw-semibold">/mes</p>
                                    </div>
                                    <a href="" className="link-black d-flex justify-content-center my-3">Comenzar</a>                                    
                                </div>
                                <div className="card-body border-0 p-0 m-0">
                                    <ul className="p-0 m-0">
                                        <li><span className="me-2">✔</span>Evita la clasificacion erronea con herramientas impulsadas por la IA, contratos que cumplan con los requisitos locales o deja que Deel contrate, gestione y pague a los trabajadores independientes en tu nombre.</li>
                                        <li><span className="me-2">✔</span>Cobra automaticamente los acuerdos fiscales locales</li>
                                        <li><span className="me-2">✔</span>Paga a tu equipo de forma masiva con mas de 15 metodos flexibles en mas de 120 monedas</li>
                                        <li><span className="me-2">✔</span>Automatiza la facturacion, los gastos, el tiempo libre y mas</li>
                                        <li><span className="me-2">✔</span>Deel Card y pago flexible para trabajadores independientes</li>
                                        <li><span className="me-2">✔</span>Beneficios, descuentos y soporte 24/7 para trabajadores independientes</li>
                                    </ul>
                                </div>
                                <div className="card-footer text-center py-3 bg-white">
                                    <h5><i class='bx bx-world me-2'></i>Disponible en mas de 150 paises</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 my-3">
                            <div className="card text-start p-4 card-border-blue rounded-4">
                                <div className="card-head">
                                    <h4>EOR de Deel</h4>
                                    <p>¿Quieres ampliar o contratar en el extranjero sin entidad? Contrata empleados en mas de 150 paises a traves de nuestros servicios internos de EOR.</p>
                                    <p className="fw-semibold mb-0">A partir de</p>
                                    <div className="d-flex align-items-center">
                                        <h3 className="me-2">$599</h3>
                                        <p className="mb-0 fw-semibold">/mes</p>
                                    </div>
                                    <a href="" className="link-black d-flex justify-content-center my-3">Comenzar</a>                                    
                                </div>
                                <div className="card-body border-0 p-0 m-0">
                                    <ul className="p-0 m-0">
                                        <li><span className="me-2">✔</span>Contrata empleados internacionales y permitenos manejar la nomina, los impuestos y los beneficios locales.</li>
                                        <li><span className="me-2">✔</span>Obten un cumplimiento legal de 360° de los empleados, desde la recopilacion de documentos localizados hasta las politicas de licencias y horas extras y la capacitacion obligatoria.</li>
                                        <li><span className="me-2">✔</span>Dar paquetes de beneficios competitivos adaptados a cada pais</li>
                                        <li><span className="me-2">✔</span>Obten informes centralizados y personalizables sobre gastos, retencion y compensacion</li>
                                        <li><span className="me-2">✔</span>Agiliza el trabajo con mas de 68 integraciones para RR.HH, finanzas y mas</li>
                                        <li><span className="me-2">✔</span>Obten soporte de mas de 200 expertos legales y de RR.HH locales</li>
                                        <li><span className="me-2">✔</span>Asistencia 24/7 y un gerente de CSM dedicado</li>
                                    </ul>
                                </div>
                                <div className="card-footer text-center py-3 bg-white">
                                    <h5><i class='bx bx-world me-2'></i>Disponible en mas de 150 paises</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 my-3">
                            <div className="card text-start p-4 card-border-blue rounded-4">
                                <div className="card-head">
                                    <h4>Nomina de Deel</h4>
                                    <p>¿Procesas nominas a nivel internacional? Dejanos encargarnos de la nomina de tus propias entidades.</p>
                                    <p className="fw-semibold mb-0">A partir de</p>
                                    <div className="d-flex align-items-center">
                                        <h3 className="me-2">$29</h3>
                                        <p className="mb-0 fw-semibold">por empleado al mes</p>
                                    </div>
                                    <a href="" className="link-black d-flex justify-content-center my-3">Comenzar</a>                                    
                                </div>
                                <div className="card-body border-0 p-0 m-0">
                                    <ul className="p-0 m-0">
                                        <li><span className="me-2">✔</span>Expertos internos en nomina</li>
                                        <li><span className="me-2">✔</span>Asistencia rapida y confiable, 24/7</li>
                                        <li><span className="me-2">✔</span>Un POC para cada pais</li>
                                        <li><span className="me-2">✔</span>Creacion y entrega de recibos de pago</li>
                                        <li><span className="me-2">✔</span>Administracion de beneficios y deducciones para colaboradores</li>
                                        <li><span className="me-2">✔</span>Presentacion local ante las autoridades</li>
                                        <li><span className="me-2">✔</span>Informes personalizables</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 my-3">
                            <div className="card text-start p-4 card-border-blue rounded-4">
                                <div className="card-head">
                                    <h4>US payroll de Deel</h4>
                                    <p>Gestiona la nomina sin problemas en EE.UU con nuestros calculos inteligentes e instantaneos.</p>
                                    <p className="fw-semibold mb-0">A partir de</p>
                                    <div className="d-flex align-items-center">
                                        <h3 className="me-2">$19</h3>
                                        <p className="mb-0 fw-semibold">por empleado al mes</p>
                                    </div>
                                    <a href="" className="link-black d-flex justify-content-center my-3">Comenzar</a>                                    
                                </div>
                                <div className="card-body border-0 p-0 m-0">
                                    <ul className="p-0 m-0">
                                        <li><span className="me-2">✔</span>Obten calculos instantaneos de impuestos y salarios</li>
                                        <li><span className="me-2">✔</span>Beneficiate del soporte de registro en los 50 estados</li>
                                        <li><span className="me-2">✔</span>Presentacion y remision de impuestos federales, estatales y locales</li>
                                        <li><span className="me-2">✔</span>Automatiza los formularios W-2, W-4, 1099 y las declaraciones de nuevos empleados</li>
                                        <li><span className="me-2">✔</span>Obten soporte experto para el cumplimiento legal bajo demanda de la nomina</li>
                                        <li><span className="me-2">✔</span>Agiliza la administracion de nominas con mas de 20 integraciones de contabilidad</li>
                                        <li><span className="me-2">✔</span>Asistencia 24/7 y un gerente de CSm dedicado</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 my-3">
                            <div className="card text-start p-4 card-border-blue rounded-4">
                                <div className="card-head">
                                    <h4>US PEO de Deel</h4>
                                    <p>Simplifica tus recursos humanos, nómina y beneficios para los empleados contratados en los 50 estados de EE. UU.</p>
                                    <p className="fw-semibold mb-0">A partir de</p>
                                    <div className="d-flex align-items-center">
                                        <h3 className="me-2">$79</h3>
                                        <p className="mb-0 fw-semibold">por empleado al mes</p>
                                    </div>
                                    <a href="" className="link-black d-flex justify-content-center my-3">Comenzar</a>                                    
                                </div>
                                <div className="card-body border-0 p-0 m-0">
                                    <ul className="p-0 m-0">
                                        <li><span className="me-2">✔</span>Cumplimiento legal de los nuevos empleados, nóminas e impuestos en cualquier estado</li>
                                        <li><span className="me-2">✔</span>Cumplimiento tributario: desde las cuentas fiscales estatales de desempleo hasta la compensación laboral</li>
                                        <li><span className="me-2">✔</span>Cumplimiento legal 360º: recopilación de documentos específicos del estado, políticas de licencias y horas extras, y capacitación obligatoria</li>
                                        <li><span className="me-2">✔</span>Beneficios de Fortune 500 a un precio asequible</li>
                                        <li><span className="me-2">✔</span>Informes centralizados y personalizables</li>
                                        <li><span className="me-2">✔</span>Expertos en RR.HH. para ayudarte a afrontar las complejidades de tu personal en EE.UU.</li>
                                        <li><span className="me-2">✔</span>Asistencia 24/7 y un gerente de CSM dedicado</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center my-3 mb-5">
                        <img src="https://deel-website-media-prod.s3.amazonaws.com/Videochat_a7a3ffd487.png" alt="" className="img-fluid me-3 icon-small" />
                        <h3>Operaciones de RR. HH.
                        </h3>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4 my-3">
                            <div className="card text-start p-4 card-border-blue rounded-4">
                                <div className="card-head">
                                    <h4>Deel HR</h4>
                                    <p>La plataforma de RR.HH. global gratuita, que se adapta a tu negocio</p>
                                    <div className="d-flex align-items-center">
                                        <h3 className="me-2">GRATIS</h3>
                                    </div>
                                    <a href="" className="link-black d-flex justify-content-center my-3">Comenzar</a>                                    
                                </div>
                                <div className="card-body border-0 p-0 m-0">
                                    <ul className="p-0 m-0">
                                        <li><span className="me-2">✔</span>Gestiona todo lo relacionado con RR.HH. para empleados globales con una sola herramienta</li>
                                        <li><span className="me-2">✔</span>Manten el cumplimiento legal con una cobertura y localizacion global inigualables</li>
                                        <li><span className="me-2">✔</span>Gestiona el tiempo libre y los gastos</li>
                                        <li><span className="me-2">✔</span>Crea organigramas e informes globales</li>
                                        <li><span className="me-2">✔</span>Accede a potentes dashboards, como costos globales, personal, DEI y mas</li>
                                        <li><span className="me-2">✔</span>Almacenar y firmar documentos</li>
                                        <li><span className="me-2">✔</span>Automatiza procesos, como la incorporacion, la desvinculacion y la gestion del acceso a las herramientas</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 my-3">
                            <div className="card text-start p-4 card-border-blue rounded-4">
                                <div className="card-head">
                                    <h5>Deel Engage</h5>
                                    <p>Todo lo que necesitas para impulsar el rendimiento, el crecimiento y el compromiso.</p>
                                    <p className="fw-semibold mb-0">A partir de</p>
                                    <div className="d-flex align-items-center">
                                        <h3 className="me-2">$20</h3>
                                        <p className="mb-0 fw-semibold">por empleado al mes</p>
                                    </div>
                                    <a href="" className="link-black d-flex justify-content-center my-3">Comenzar</a>                                    
                                </div>
                                <div className="card-body border-0 p-0 m-0 row">
                                    <div className="col-12 col-lg-4 my-3">
                                        <h5>Rendimiento</h5>
                                        <ul className="p-0 m-0">
                                            <li><span className="me-2">✔</span>Crea y gestionar objetivos para personas, equipos y empresas</li>
                                            <li><span className="me-2">✔</span>Realiza revisiones de retroalimentación 360° altamente personalizadas</li>
                                            <li><span className="me-2">✔</span>Ajusta las calificaciones para aumentar la equidad</li>
                                            <li><span className="me-2">✔</span>Visualiza los resultados mediante mapas de calor, cuadrículas de 9 cuadros y mucho más</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-lg-4 my-3">
                                        <h5>Aprendizaje</h5>
                                        <ul className="p-0 m-0">
                                            <li><span className="me-2">✔</span>Crea fácilmente tus propios cursos con solo arrastrar y soltar</li>
                                            <li><span className="me-2">✔</span>Utiliza la IA para crear contenido más rápido</li>
                                            <li><span className="me-2">✔</span>Agrega cuestionarios, encuestas, tareas y mucho más</li>
                                            <li><span className="me-2">✔</span>Asigna cursos automáticamente según tus criterios</li>
                                            <li><span className="me-2">✔</span>Próximamente: encuentra miles de cursos de proveedores populares</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-lg-4 my-3">
                                        <h5>Desarrollo profesional</h5>
                                        <ul className="p-0 m-0">
                                            <li><span className="me-2">✔</span>Crea marcos de trayectoria profesional para cada puesto y nivel</li>
                                            <li><span className="me-2">✔</span>Utiliza la IA para redactar marcos detallados en cuestión de minutos</li>
                                            <li><span className="me-2">✔</span>Gestiona las competencias en un lugar central</li>
                                        </ul>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center my-3 mb-5">
                        <img src="https://deel-website-media-prod.s3.amazonaws.com/Icon_9cffe62d38.png" alt="" className="img-fluid me-3 icon-small" />
                        <h3>Inmigracion
                        </h3>
                    </div>
                    <div className="row">
                    <div className="col-12 my-3">
                            <div className="card text-start p-4 card-border-blue rounded-4">
                                <div className="row">
                                    <div className="col-12 col-lg-4 my-3">
                                        <h4>Deel Immigration</h4>
                                        <p>¿Buscas reubicar trabajadores? Nuestro equipo interno se encarga de todo, desde el patrocinio de visas hasta la incorporación, y puede ocuparse de la nómina, la declaración de impuestos y mucho más.</p>
                                        <a href="" className="link-black d-flex justify-content-center my-3">Obten un presupuesto personalizado</a>     
                                    </div>
                                    <div className="col-12 col-lg-5 my-3">
                                        <h5>Soluciones globales para cualquier trabajador</h5>
                                        <ul className="p-0 m-0">
                                            <li><span className="me-2">✔</span>Expertos internos en inmigración</li>
                                            <li><span className="me-2">✔</span>Cobertura en 40+ países y contando</li>
                                            <li><span className="me-2">✔</span>Verifica gratuitamente la elegibilidad para tu empleado</li>
                                            <li><span className="me-2">✔</span>Visualiza el proceso con una vista de línea de tiempo</li>
                                            <li><span className="me-2">✔</span>Obtén apoyo de un coordinador de casos dedicado</li>
                                            <li><span className="me-2">✔</span>Haz un seguimiento de los casos con un rastreador avanzado integrado en la app</li>
                                            <li><span className="me-2">✔</span>Administra todas las visas desde una vista consolidada</li>
                                            <li><span className="me-2">✔</span>Experiencia en EE. UU. con una tasa de aprobación superior a 90</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-lg-3 my-3">
                                        <h5>Visas y Green Cards de EE.UU.</h5>
                                        <ul className="p-0 m-0">
                                            <li><span className="me-2">✔</span>Visa O-1A</li>
                                            <li><span className="me-2">✔</span>Green Card EB-2 NIW</li>
                                            <li><span className="me-2">✔</span>Visa H-1B</li>
                                            <li><span className="me-2">✔</span>Green Card EB-1A</li>
                                            <li><span className="me-2">✔</span>Visa L-1A</li>
                                            <li><span className="me-2">✔</span>Visa O-1B</li>
                                            <li><span className="me-2">✔</span>Visa TN</li>
                                            <li><span className="me-2">✔</span>E-2 Visa</li>
                                            <li><span className="me-2">✔</span>...y mas</li>
                                        </ul>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-12 col-lg-6 offset-lg-3 mb-5">
                            <span>ADD-ONS OPCIONALES</span>
                            <h3>Disfruta de complementos flexibles que te ayudaran a hacer crecer tu equipo global</h3>                            
                        </div>
                        <div className="col-6 col-md-4 p-4 img-icon my-3">
                            <img className="img-fluid mb-2" src="https://deel-website-media-prod.s3.amazonaws.com/Background_Checks_da51fb98ec.png" alt="" />
                            <h4>Background Checks</h4>
                            <p>Automatiza la seleccion de nuevos empleados</p>
                            <a href="" className="link-white">Conoce mas</a>
                        </div>
                        <div className="col-6 col-md-4 p-4 img-icon my-3">
                            <img className="img-fluid mb-2" src="https://deel-website-media-prod.s3.amazonaws.com/Equipment_5472e3c5ca.png" alt="" />
                            <h4>Equipo</h4>
                            <p>Equipa a los nuevos empleados en cualquier lugar</p>
                            <a href="" className="link-white">Conoce mas</a>
                        </div>
                        <div className="col-6 col-md-4 p-4 img-icon my-3">
                            <img className="img-fluid mb-2" src="https://deel-website-media-prod.s3.amazonaws.com/Coworking_37e4d71617.png" alt="" />
                            <h4>Espacios de trabajo</h4>
                            <p>Proporciona acceso a WeWork</p>
                            <a href="" className="link-white">Conoce mas</a>
                        </div>
                        <div className="col-6 col-md-4 p-4 img-icon my-3">
                            <img className="img-fluid mb-2" src="https://deel-website-media-prod.s3.amazonaws.com/Deel_Shield_2f63c6f5a1.png" alt="" />
                            <h4>Deel Contratista registrado</h4>
                            <p>Evita la clasificacion erronea</p>
                            <a href="" className="link-white">Conoce mas</a>
                        </div>
                        <div className="col-6 col-md-4 p-4 img-icon my-3">
                            <img className="img-fluid mb-2" src="https://deel-website-media-prod.s3.amazonaws.com/Equity_d8f4dd422f.png" alt="" />
                            <h4>Equity</h4>
                            <p>Gestiona las stock options de forma legal</p>
                            <a href="" className="link-white">Conoce mas</a>
                        </div>
                        <div className="col-6 col-md-4 p-4 img-icon my-3">
                            <img className="img-fluid mb-2" src="https://deel-website-media-prod.s3.amazonaws.com/Location_Pin_833a61e26d.png" alt="" />
                            <h4>Configuracion de entidad</h4>
                            <p>Amplia tus operaciones comerciales</p>
                            <a href="" className="link-white">Conoce mas</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-light-blue py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 pe-5 align-self-center text-start">
                            <span>COBERTURA GLOBAL</span>
                            <h3 className="fw-bolder">Contrata y paga a tu equipo global en mas de 150 paises</h3>
                            <select class="form-select rounded-5 p-3 my-4" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">
                                    <div className="d-flex">
                                        <img src="https://deel-website-media-prod.s3.amazonaws.com/flag_Bahrain_29a303c6d7.svg" className="img-fluid h-100 w-100" alt="" />
                                        <h5>Bahrain</h5>
                                    </div>
                                </option>
                                <option value="2"></option>
                                <option value="3"></option>
                            </select>
                        </div>
                        <div className="col-12 col-md-6 p-5">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Coverage_Teaser_min_5d9c6ade16.jpg" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-6 text-start">
                            <h3>Descubre por que mas de 35.000 empresas lideres mundiales confian en Deel</h3>
                        </div>
                        <div className="col-12 border-bottom py-5">
                            <div className="row">
                                <div className="d-flex overflow-x-auto mini-cards-color">
                                    <div className="col-12 col-md-5 me-4 my-3">
                                        <div className="card rounded-4 card-yellow d-flex flex-column justify-content-between p-4 text-start">
                                            <div className="card-head py-3 mb-2">
                                                <img src="https://deel-website-media-prod.s3.amazonaws.com/logo_turing_black_f8baadc2a5.svg" alt="" />
                                            </div>
                                            <div className="card-body mx-0 py-4 border-0 px-0">
                                                <h3>Antes de que Deel contratara y pagara a nuestro equipo global, todo era un calvario. Ahora, para nuestros cientos de trabajadores independientes, podemos gestionar sus contratos, compliance y pagos en un solo lugar. Deel ha cambiado el mundo de la contaminacion remota para nosotros.</h3>
                                            </div>
                                            <div className="card-footer px-0 bg-transparent border-0 d-flex align-items-center">
                                                <img src="https://deel-website-media-prod.s3.amazonaws.com/client_turing_jonathan_siddharth_c38e774f22.png" className="me-3" alt="" />
                                                <div className="d-flex flex-column">
                                                    <span>Jonathan Siddharth</span>
                                                    <span>CEO de Turing</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 me-4 my-3">
                                        <div className="card rounded-4 card-light-blue d-flex flex-column justify-content-between p-4 text-start">
                                            <div className="card-head py-3 mb-2">
                                                <img src="https://deel-website-media-prod.s3.amazonaws.com/logo_mainstreet_black_c942131ed9.svg" alt="" />
                                            </div>
                                            <div className="card-body mx-0 py-4 border-0 px-0">
                                                <h3>"A nuestros equipos les gusta mucho Deel. Ha facilitado las cosas para ambas partes. Tenemos informes sobre lo que hemos pagado a nuestros contratistas y pueden ver el historial de sus pagos en un solo lugar".</h3>
                                            </div>
                                            <div className="card-footer px-0 bg-transparent border-0 d-flex align-items-center">
                                                <img src="https://deel-website-media-prod.s3.amazonaws.com/client_mainstreet_doug_ludlow_7d41b70803.png" className="me-3" alt="" />
                                                <div className="d-flex flex-column">
                                                    <span>Doug Ludlow</span>
                                                    <span>CEO de MainStreet</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 me-4 my-3">
                                        <div className="card rounded-4 card-violet d-flex flex-column justify-content-between p-4 text-start">
                                            <div className="card-head py-3 mb-2">
                                                <img src="https://deel-website-media-prod.s3.amazonaws.com/logo_makerpad_black_06909f3327.svg" alt="" />
                                            </div>
                                            <div className="card-body mx-0 py-4 border-0 px-0">
                                                <h3>“Deel ha hecho súper simple para una pequeña empresa global contratar y pagar a la gente. La plataforma es extremadamente fácil de navegar, realmente fácil de usar y el soporte de Deel ha ido más allá de cualquier solicitud que hayamos tenido”.</h3>
                                            </div>
                                            <div className="card-footer px-0 bg-transparent border-0 d-flex align-items-center">
                                                <img src="https://deel-website-media-prod.s3.amazonaws.com/client_makerpad_ben_tossel_251405ea5a.png" className="me-3" alt="" />
                                                <div className="d-flex flex-column">
                                                    <span>DBen Tossel</span>
                                                    <span>Fundador de Makerpad</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-light-blue py-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            <h3 className="mb-5 fw-semibold">Deel facilita el crecimiento de los equipos remotos e internacionales</h3>
                        </div>
                        <div className="col-12 col-md-4">
                            <h1>150+</h1>
                            <h4 className="color-grey">paises</h4>
                        </div>
                        <div className="col-12 col-md-4">
                            <h1>35.000+</h1>
                            <h4 className="color-grey">clientes</h4>
                        </div>
                        <div className="col-12 col-md-4">
                            <h1>200+</h1>
                            <h4 className="color-grey">expertos internos</h4>
                        </div>
                        <div className="col-12 my-5">
                            <a href="" className="link-black py-3 px-4 fw-semibold">Agendar un demo</a>         
                        </div>
                        
                    </div>
                </div>
            </section>

        </div>
    )
}
export default Pricing