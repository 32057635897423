import { Link } from 'react-router-dom';
import './Components.css'

function Navbar () {
    return (
        <header>
            <nav class="navbar navbar-expand-lg bg-body-tertiary d-none d-xl-block">
                <div class="container">
                    <Link class="navbar-brand" to='/'><img src="https://cdn.asp.events/CLIENT_M_Media__147F6B18_D846_E6EB_60DAA8F6CE5A26EA/sites/FWS-2024/media/libraries/fowc-2024/Deel_logo_wordmark_black_no_backgr-(2).png/fit-in/700x9999/filters:no_upscale()" alt="" className="img-fluid logo" /></Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarText">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link fw-semibold dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Plataforma
                            </a>
                            <div className="row dropdown-menu p-0">
                                <div className="col-6 p-4">
                                    <span>PRODUCTOS GLOBALES DE RR. HH. Y NOMINA</span>
                                    <div className="row row-contenedor-nav me-2 border-top py-3 my-3">
                                        <div className="col-12 col-md-6 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/person_pin_8d8c089472.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>EOR de Deel</h5>
                                                            <p>Contrata empleados en el extranjero</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-6 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/recent_actors_9bdb77b8bb.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>Deel HRIS</h5>
                                                            <p>Gestiona a toda tu fuerza laboral a traves de Deel</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-6 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/group_40d1038349.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>Deel Contractor</h5>
                                                            <p>Contrata trabajadores independientes a nivel mundial</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-6 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/explore_92194d32b9.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>Deel Immigration</h5>
                                                            <p>Obten soporte para visas en todo el mundo</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-6 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/globe_4313b1fd3c.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>Global Payroll de Deel</h5>
                                                            <p>Gestiona nominas en mas de 100 paises</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-6 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/bar_chart_bd69eca17c.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>Deel Engage</h5>
                                                            <p>Crea equipos de alto rendimiento con la mitad del trabajo</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-6 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/monetization_on_0ab071133d.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>US payroll de Deel</h5>
                                                            <p>Procesa la nomina de EE. UU. en minutos</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-6 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/contacts_2870cd477d.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>Deel PEO</h5>
                                                            <p>Beneficios y cumplimiento legal para tu equipo de EE. UU.</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 col-md-6 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/offline_bolt_bc5ca957a1.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>Plugins de Deel</h5>
                                                            <p>Plugins de Slack que permiten a tu equipo mantener la productividad y la participacion</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 p-4">
                                    <span>PLATAFORMA DEEL</span>
                                    <div className="row row-contenedor-nav me-2 border-top py-3 my-3">
                                        <div className="col-12 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/chat_13872841a6.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>Deel Expert AI</h5>
                                                            <p>Obten rapidamente informacion global de RR. HH. con IA</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/apps_2cef5a26a3.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>Deel App Store</h5>
                                                            <p>Automatiza tus procesos de RRHH</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 content-nav px-3 rounded-4 my-2">
                                            <a href="">
                                                <div className="row">
                                                    <div className="col-auto my-1 card-icon-nav rounded-4"><img src="https://deel-website-media-prod.s3.amazonaws.com/code_1ebc017659.svg" alt=""/></div>
                                                    <div className="col my-1">
                                                        <a href="">
                                                            <h5>API de Deel</h5>
                                                            <p>Potencia tus productos y procesos</p>  
                                                        </a>
                                                    </div>                                                    
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 bg-pink p-4 rounded-5">
                                    <span>COMPLEMENTOS</span>
                                    <ul className='px-0 my-3 border-top'>
                                        <li className='content-nav'><a href=""><h5>Verificacion de antecedentes</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Gestion de Equity</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Equipo</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Espacios de trabajo</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Deel Contratista registrado</h5></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item dropdown dropdown-left">
                            <a class="nav-link fw-semibold dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Soluciones
                            </a>
                            <div className="row dropdown-menu">
                                <div className="col-6 col-xl-3">
                                    <span>¿COMO PODEMOS AYUDAR?</span>
                                    <ul className='px-0 my-3 border-top'>
                                        <li className='content-nav'><a href=""><h5>Contrata a cualquier persona, en cualquier lugar</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Consolida las operaciones de recursos humanos</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Automatiza la administracion de nominas</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Mejora la satisfaccion del equipo</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Expandete a los mercados rapidamente</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Reubica a los miembros del equipo</h5></a></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <span>PARA EQUIPOS</span>
                                    <ul className='px-0 my-3 border-top'>
                                        <li className='content-nav'><a href=""><h5>RR. HH.</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Finanzas</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Legal</h5></a></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <span>POR TAMAÑO DE LA EMPRESA</span>
                                    <ul className='px-0 my-3 border-top'>
                                        <li className='content-nav'><a href=""><h5>Empresas</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Startups</h5></a></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <span>POR INDUSTRIA</span>
                                    <ul className='px-0 my-3 border-top'>
                                        <li className='content-nav'><a href=""><h5>Educacion</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Servicios financieros</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Atencion medica</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Retail</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Todas las industrias</h5></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item dropdown dropdown-left">
                            <a class="nav-link fw-semibold dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Recursos
                            </a>
                            <div className="row dropdown-menu">
                                <div className="col-6 col-lg-4">
                                    <span>APRENDER Y CONECTAR</span>
                                    <ul className='px-0 my-3 border-top'>
                                        <li className='content-nav'><a href=""><h5>Blog</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Biblioteca de recursos</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Evento y webinars</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Help center</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Glosario del trabajo global</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Partner program</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Trabaja con nosotros</h5></a></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-lg-5">
                                    <span>HERRAMIENTAS DE CONTRATACION GLOBAL</span>
                                    <ul className='px-0 my-3 border-top'>
                                        <li className='content-nav'><a href=""><h5>Cuestionario de clasificacion erronea</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Salary Insights Globales</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Comparacion de empleo global</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Herramienta de beneficios globales</h5></a></li>
                                        <li className='content-nav'><a href=""><h5>Todas las herramientas</h5></a></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-lg-3 content-nav card-light-violet p-4 rounded-4 my-2">
                                    <div className="row row-contenedor-nav">
                                        <a href="">
                                            <div className="row">
                                                <div className="col-4 col-lg-12 my-1 rounded-4"><img className='img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Calculator_4c4de53a8b.png" alt=""/></div>
                                                    <div className="col-8 col-lg-12 my-1">
                                                    <a href="">
                                                        <h4 className='fw-normal'>Calcula los costos de una posible nueva contratacion</h4>
                                                        <h5 className='fw-semibold mt-3'>Conoce mas</h5>  
                                                    </a>
                                                </div>                                                    
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link fw-semibold" to='/pricing'>Precios</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link fw-semibold" to='/competitors'>Competidores</Link>
                        </li>
                    </ul>
                    <a href="" className="link-white me-3">Iniciar Sesion</a>
                    <Link to='/' className="link-black">Solicitar un demo</Link>
                    </div>
                </div>
            </nav> 
            <nav class="navbar navbar-expand-lg bg-body-tertiary d-xl-none">
                <div class="container">
                    <a class="navbar-brand" href="#"><img src="https://cdn.asp.events/CLIENT_M_Media__147F6B18_D846_E6EB_60DAA8F6CE5A26EA/sites/FWS-2024/media/libraries/fowc-2024/Deel_logo_wordmark_black_no_backgr-(2).png/fit-in/700x9999/filters:no_upscale()" alt="" className="img-fluid logo" /></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarText">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link fw-semibold dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Plataforma
                            </a>
                            <div className="row dropdown-menu">
                                <div className="col-12">
                                    <span>CONTRATAR EN CUALQUIER LUGAR</span>
                                    <div className="row row-contenedor-nav me-2 border-top py-3 my-3">
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>EOR de Deel</h5>
                                                <p>Contrata empleados en el extranjero</p>  
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Gestion de trabajadores independientes de Deel</h5>
                                                <p>Contrata trabajadores independientes a nivel mundial</p>                                            
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Deel Shleld</h5>
                                                <p>Evita los riesgos de clasificacion erronea</p>                                            
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Deel Immigration</h5>
                                                <p>Obten soporte para visas en todo el mundo</p>                                            
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Verificacion de antecedentes</h5>
                                                <p>Ejecuta comprobaciones de compliance a nivel mundial</p>                                            
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <span>GESTIONA TU EQUIPO</span>
                                    <div className="row row-contenedor-nav me-2 border-top py-3 my-3">
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Deel HR</h5>
                                                <p>Gestiona a toda tu fuerza laboral a traves de Deel</p>                                            
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Deel Engage</h5>
                                                <p>Crea equipos de alto rendimiento con la mitad del trabajo</p>                                            
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Plugins de Deel</h5>
                                                <p>Plugins de Slack que permiten a tu equipo mantener la productividad y la participacion</p>
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Equipo</h5>
                                                <p>Equipa a tu equipo global</p>
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Equity</h5>
                                                <p>Brinda Equity a tus equipos globales</p>
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>App Store</h5>
                                                <p>Automatiza tus procesos de RRHH</p>
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>API</h5>
                                                <p>Potencia tus productos y procesos</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <span>PAGA EN TODO EL MUNDO</span>
                                    <div className="row row-contenedor-nav border-top py-3 my-3">
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Nomina de Deel</h5>
                                                <p>Gestiona nominas en mas de 100 paises</p>
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>US payroll de Deel</h5>
                                                <p>Procesa la nomina de EEUU en minutos</p>
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>US PEO de Deel</h5>
                                                <p>Beneficios y cumplimiento legal para tu equipo de EE.UU</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fondo-nav"></div>
                        </li>
                        <li class="nav-item dropdown dropdown-left">
                            <a class="nav-link fw-semibold dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Casos de uso
                            </a>
                            <div className="row dropdown-menu">
                                <div className="col-12">
                                    <span>TAMAÑO DEL NEGOCIO</span>
                                    <div className="row row-contenedor-nav me-2 border-top py-3 my-3">
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Startup</h5>
                                                <p>Integra Deel a tus flujos de trabajo existentes sin esfuerzo</p>  
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Empresas</h5>
                                                <p>Comienza bien, contratando legal y globalmente</p>                                            
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <span>SOLUCIONES</span>
                                    <div className="row row-contenedor-nav me-2 border-top py-3 my-3">
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Mantente al dia con el compliance</h5>
                                                <p>Contrata tranquilamente</p>                                            
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Ejecuta los Pagos Globales</h5>
                                                <p>Gestiona nominas en mas de 100 paises</p>                                            
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Industrias</h5>
                                                <p>Mantente a la vanguardia independientemente de tu sector</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <span>PARA EQUIPOS</span>
                                    <div className="row row-contenedor-nav border-top py-3 my-3">
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Equipos financieros</h5>
                                                <p>Ahorra horas en admin y pagos</p>
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Equipos legales</h5>
                                                <p>Contrata en el extranjero sin tener que hacer la gestion legal</p>
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Gerentes de contratacion</h5>
                                                <p>Consigue tus objetivos de contratacion rapidamente</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item dropdown dropdown-left">
                            <a class="nav-link fw-semibold dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Recursos
                            </a>
                            <div className="row dropdown-menu">
                                <div className="col-12">
                                    <span>HERRAMIENTAS DE CONTRATACION</span>
                                    <div className="row row-contenedor-nav me-2 border-top py-3 my-3">
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Guia de Contratacion Global</h5>
                                                <p>Mas informacion sobre las mejores practicas de contratacion en paises remotos</p>  
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Empresas</h5>
                                                <p>Comienza bien, contratando legal y globalmente</p>                                            
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <span>SOLUCIONES</span>
                                    <div className="row row-contenedor-nav me-2 border-top py-3 my-3">
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Mantente al dia con el compliance</h5>
                                                <p>Contrata tranquilamente</p>                                            
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Ejecuta los Pagos Globales</h5>
                                                <p>Gestiona nominas en mas de 100 paises</p>                                            
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Industrias</h5>
                                                <p>Mantente a la vanguardia independientemente de tu sector</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <span>PARA EQUIPOS</span>
                                    <div className="row row-contenedor-nav border-top py-3 my-3">
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Equipos financieros</h5>
                                                <p>Ahorra horas en admin y pagos</p>
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Equipos legales</h5>
                                                <p>Contrata en el extranjero sin tener que hacer la gestion legal</p>
                                            </a>
                                        </div>
                                        <div className="col-6 my-1">
                                            <a href="">
                                                <h5>Gerentes de contratacion</h5>
                                                <p>Consigue tus objetivos de contratacion rapidamente</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link fw-semibold" href="../pages/Princing.jsx">Precios</a>
                        </li>
                    </ul>
                    <div className="d-flex justify-content-center">
                        <a href="" className="link-white me-3">Iniciar Sesion</a>
                        <a href="" className="link-black">Solicitar un demo</a>                        
                    </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}
export default Navbar;
