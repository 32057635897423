import './Main.css'
import './Medias-queries.css'
import g2 from '../images/g2.png'

function Home () {
    return (
        <div className="Home">
            
            <section className="bg-light-blue py-5">
                <div className="container">
                    <div className="row text-center justify-content-center">
                        <div className="col-12 col-md-7">
                            <h1 className="mb-4">Nomina, RR. HH. y compliance para equipos globales</h1>
                            <p className='mb-4'>Deel ayuda a decenas de miles de empresas a expandirse globalmente con una velocidad, flexibilidad y cumplimiento legal inigualables. Obten nuestra plataforma todo en uno que simplifica la forma en que gestionas tu equipo global: onboarding, offboarding y todo lo demas</p>
                            <a href="" className="link-black">Comenzar</a>
                        </div>
                        <div className="col-12">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Homepage_MVP_Composition_Desktop_7656d339ed.png" className='img-fluid rounded-3 my-5 d-none d-md-block' alt="" />
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Platform_Home_Mobile_a806863221.png" alt="" className="img-fluid rounded-3 my-5 d-block d-md-none" />
                        </div>
                        <span>CON LA CONFIANZA DE MAS DE 35.000 COMPAÑIAS, DESDE STARTUPS HASTA MULTINACIONALES</span>
                        <div className="list-sponsors py-5">
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Shopify_7f02141038.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Klarna_bf7fb6ca46.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Reddit_4490a08e66.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Nike_3382174210.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Zapier_157e95a3cc.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/logo_cloudflare_dark_gray_d516e1780c.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Jelly_Belly_631155cedf.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Revolut_1b9db47f53.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Forever_21_f021dce37c.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Calvin_Klein_6ff1b11fd9.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Nubank_a79545ba53.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Intercom_0947a68c35.svg" alt="" /></div>
                            <div className="sponsor"><img className='
                            img-fluid' src="https://deel-website-media-prod.s3.amazonaws.com/Hello_Fresh_SE_cce35337e8.svg" alt="" /></div>
                            <div className="sponsor">
                                <img src="https://deel-website-media-prod.s3.amazonaws.com/logo_bcg_dark_3d73aec63d.svg" alt="" className="img-fluid" /></div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container text-start py-5">
                    <div className="row">
                        <div className="col-12 col-lg-6 py-5 align-self-center">
                            <span>PLATAFORMA GLOBAL PEOPLE</span>
                            <h2 className='py-3 fw-semibold'>Escala globalmente con velocidad y facilidad</h2>
                            <p className='mb-5'>Deel esta diseñado para adaptarse a organizaciones de todos los tamaños, desde pequeños equipos hasta empresas de miles de personas. Tanto si quieres contratar en todo el mundo sin abrir entidades legales, optimizar HR para tu equipo global o pagar a todo tipo de trabajadores en cualquier lugar con pagos consolidados, Deel lo hace de una forma totalmente legal.</p>
                            <a href="" className="link-black">Comenzar</a>
                        </div>
                        <div className="col-12 col-lg-6 py-5 p-3">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/For_Global_Teams_min_7f70a3d29a.jpg" alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <h3>Por que las empresas de todo el mundo prefieren Deel para expandir su equipo a nivel mundial.</h3>
                        </div>
                        <div className="col-12 border-bottom py-5">
                            <div className="row">
                                <div className="d-flex overflow-x-auto mini-cards-color">
                                    <div className="col-12 col-md-5 me-4 my-3">
                                        <div className="card rounded-4 card-yellow d-flex flex-column justify-content-between p-4">
                                            <h3>"Somos un excliente de Remote. Normalmente no escribo reseñas pero, sinceramente, no pude contenerme. Hay mucho que decir, pero las expectativas versus la realidad para Remote.com es una brecha triste/enorme".</h3>
                                            <span>Jadd Baya</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 me-4 my-3">
                                        <div className="card rounded-4 card-light-blue d-flex flex-column justify-content-between p-4">
                                            <h3>"La mejor solucion de nominas, y punto. Hemos utilizado todas las soluciones de nominas: Gusto, Rippling, la que quieras. Deel es la unica que... Lo hemos usado durante 4 años y no hemos mirado atras".</h3>
                                            <span>Jeremy C.</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 me-4 my-3">
                                        <div className="card rounded-4 card-violet d-flex flex-column justify-content-between p-4">
                                            <h3>"Deel facilita el envio de pagos a equipos internacionales. LLevo mas de 3 años usando Deel y nunca me cambiaria. Es un gran producto".</h3>
                                            <span>Katie R.</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 me-4 my-3">
                                        <div className="card rounded-4 card-yellow d-flex flex-column justify-content-between p-4">
                                            <h3>“Me pareció que Deel cambió todo radicalmente. La plataforma intuitiva tomó la abrumadora tarea de entender la nómina internacional y la resumió en una interfaz fácil de usar con la que un novato como yo podía navegar con confianza”.</h3>
                                            <span>Thomas L.</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 my-3">
                                        <div className="card rounded-4 card-light-blue d-flex flex-column justify-content-between p-4">
                                            <h3>“A lo largo de mis dos años con Deel, no me he encontrado con un solo problema, y el servicio al cliente ha sido nada menos que excepcional. La asistencia proactiva realmente ha mejorado mi experiencia y no podría estar más conforme con el servicio en general”.</h3>
                                            <span>John David Q.</span>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <h3 className='my-4'>Elige Deel para una experiencia lider en el sector</h3>
                        <div className="col-12 col-lg-4 img-blue">
                            <div className="row">
                                <div className="col-3 col-lg-4 align-self-center px-1">
                                    <img src="https://deel-website-media-prod.s3.amazonaws.com/User_Group_e1f4da88d2.png" alt="" className="img-fluid" />
                                </div>
                                <div className="col-9 col-lg-8 align-self-center px-1">
                                    <h1 className="mb-0">500k</h1>
                                    <p>trabajadores incorporados a nivel mundial</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 img-blue">
                            <div className="row">
                                <div className="col-3 col-lg-4 align-self-center px-1">
                                    <img src="https://deel-website-media-prod.s3.amazonaws.com/Globe_2118ad8b7b.png" alt="" className="img-fluid" />
                                </div>
                                <div className="col-9 col-lg-8 align-self-center px-1">
                                    <h1 className="mb-0">35k</h1>
                                    <p>empresas globales apoyadas</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 img-blue">
                            <div className="row">
                                <div className="col-3 col-lg-4 align-self-center px-1">
                                    <img src="https://deel-website-media-prod.s3.amazonaws.com/Bills_Coins_ff5c0d19fc.png" alt="" className="img-fluid" />
                                </div>
                                <div className="col-9 col-lg-8 align-self-center px-1">
                                    <h1 className="mb-0">US$10B</h1>
                                    <p>Nómina global procesada legalmente</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            <h2 className='my-5 text-center'>Deel es tu plataforma global de RR.HH. todo en uno</h2>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 cards-color my-3">
                            <div className="card border-0 rounded-4 p-5 card-pink">
                                <h4 className="mb-3">Paga a cualquier persona, en cualquier lugar y en minutos</h4>
                                <p>Olvidate de multiples herramientas, errores de nomina e informes poco confiables. Ejecuta la nomina para toda tu fuerza laboral global en 150 paises en una plataforma de autoservicio.</p>
                                <div className="d-flex flex-column flex-lg-row">
                                    <a href="" className="link-black my-3 text-center me-lg-2">Global Payroll de Deel</a>
                                    <a href="" className="link-black my-3 text-center">US payroll de Deel</a>
                                </div>
                                <img src="https://deel-website-media-prod.s3.amazonaws.com/Window_8a3485fa51.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 cards-color my-3">
                            <div className="card border-0 rounded-4 p-5 card-light-blue">
                                <img src="https://deel-website-media-prod.s3.amazonaws.com/Global_Reporting_dccf65d5c5.png" alt="" className="img-fluid" />
                                <h4 className="mb-3">Amplia tu equipo y contrata a nivel mundial</h4>
                                <p>Incorpora a trabajadores independientes y empleados de todo el mundo de forma legal, sin preocuparte por las leyes locales, la nomina y el cumplimiento de las normativas locales.</p>
                                <div className="d-flex flex-column flex-lg-row">
                                    <a href="" className="link-black my-3 me-3 text-center">Gestion de trabajadores independientes de Deel</a>
                                    <a href="" className="link-black my-3 text-center">EOR de Deel</a>                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 cards-color my-3">
                            <div className="card border-0 rounded-4 p-5 card-light-blue">
                                <img src="https://deel-website-media-prod.s3.amazonaws.com/Equity_Bar_18514abb52.png" alt="" className="img-fluid" />
                                <h4 className="mb-3">Dar a los equipos globales equity que cumpla con las autoridades</h4>
                                <p>Ofrece facilmente Equity en mas de 110 paises de forma legal. Deel simplifica la declaracion de impuestos y lleva tu compensacion total a un solo informe.</p>
                                <a href="" className="link-black my-3 text-center">Gestion de Equity</a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 cards-color my-3">
                            <div className="card border-0 rounded-4 p-5 card-pink">
                                <h4 className="mb-3">Consolida tus operaciones de recursos humanos</h4>
                                <p>Con Deel HR, puedes reunir todos los datos de tus empledos de todo el mundo en un unico SIRH global. Localizamos todo de manera legal, desde el onboarding hasta los dias festivos, segun la ubicacion y las leyes laborales locales.</p>
                                <a href="" className="link-black my-3 text-center">Deel HR</a>
                                <img src="https://deel-website-media-prod.s3.amazonaws.com/Hug_Bound_c3b9e7d8d0.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 cards-color my-3">
                            <div className="card border-0 rounded-4 p-5 card-pink">
                                <h4 className="mb-3">Prepara a tu equipo para el éxito con herramientas de rendimiento</h4>
                                <p>Impulsa el éxito y obtén información poderosa con nuestra gama de productos basados en AI para gestionar los objetivos, el rendimiento, el desarrollo del aprendizaje, la satisfacción del equipo y más.</p>
                                <a href="" className="link-black my-3 text-center">Deel Engage</a>
                                <img src="https://deel-website-media-prod.s3.amazonaws.com/Performance_6ce0defff7.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 cards-color my-3">
                            <div className="card border-0 rounded-4 p-5 card-yellow">
                                <img src="https://deel-website-media-prod.s3.amazonaws.com/Visa_Types_256fe18973.png" alt="" className="img-fluid" />
                                <h4 className="mb-3">Atrae y conserva talento con apoyo a la inmigración</h4>
                                <p>Encontrar y obtener la visa adecuada para un país puede llevar meses. Nuestros expertos en inmigración se encargan de todo para que puedas reubicar y retener fácilmente a los mejores talentos.</p>
                                <a href="" className="link-black my-3 text-center">Deel Immigration</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-dark py-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            <span>CUMPLIMIENTO LEGAL GLOBAL</span>
                            <h2 className='my-3 mb-4'>Desbloquea Continuous Compliance™ con Deel Compliance Hub</h2>
                            <p className='mb-5'>Mantente al tanto de los problemas del cumplimientos con las autoridades globales como nunca antes. Nuestro compliance Hub brinda acceso a las ultimas actualizaciones normativas y advertencias de riesgos, ofreciendo orientacion y alertas procesables para mejorar el compliance, todo en un solo lugar.</p>
                            <a href="" className="link-white">Comenzar</a>                            
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-4 p-lg-3 text-start py-5">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Legal_Updates_f7153a4d00.png" className='img-fluid rounded-4' alt="" />
                            <h3 className='my-3'>Lleva un registro de las ultimas actualizaciones legales</h3>
                        </div>
                        <div className="col-4 p-lg-3 text-start py-5">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Workforce_Insights_ee62bbf42f.png" alt="" className="img-fluid rounded-4" />
                            <h3 className='my-3'>Obten informacion mensual sobre la fuerza laboral</h3>
                        </div>
                        <div className="col-4 p-lg-3 text-start py-5">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Worker_Classification_0d445ad837.png" alt="" className="img-fluid rounded-4" />
                            <h3 className='my-3'>Determina facilmente la clasificacion de los trabajadores</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            <span>LA VENTAJA DE DEEL</span>
                            <h2 className='my-3 mb-4'>Avanza mas rapido con HR y nomina para tu equipo en un solo lugar</h2>
                            <p className='my-4 mb-5'>Deel es la unica plataforma que te permite combinar nomina RRHH, rendimiento y cumplimiento legal para cualquier tipo de trabajador en 150 paises en una unica plataforma. Consolida tus herramientas, reduce los gastos generales y obten informacion inigualable sobre los datos de tu peronal.</p>
                            <a href="" className="link-black">Comenzar</a>                            
                        </div>
                    </div>
                    <div className="row py-5 my-5">
                        <div className="col-12 col-md-6 col-lg-4 text-md-start my-5 p-lg-4">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Chart_392ccb9004.svg" alt="" className="img-fluid icons" />
                            <h4 className='mt-5 mb-2'>Informes Poderosos</h4>
                            <p>Desbloquea informacion inigualable sobre el gasto y el rendimiento con compensaciones, OKR, equity y mucho mas para tu equipo en un solo sistema.</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 text-md-start my-5 p-lg-4">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Asterisk_413ad7289b.svg" alt="" className="img-fluid icons" />
                            <h4 className='mt-5 mb-2'>Escalabilidad</h4>
                            <p>Desde trabajadores independientes y EOR hasta nominas y PEO, Deel es la unica plataforma que puede evolucionar con cada etapa de crecimiento de tu empresa.</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 text-md-start my-5 p-lg-4">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Messages_ffbdc2b1cd.svg" alt="" className="img-fluid icons" />
                            <h4 className='mt-5 mb-2'>Soporte Dedicado</h4>
                            <p>No dependemos de terceros, lo que significa que tendras un unico POC dedicado con el que trabajar y un FRT garantizado de 1.25 minutos.</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 text-md-start my-5 p-lg-4">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Avatars_fb897d467b.svg" alt="" className="img-fluid icons" />
                            <h4 className='mt-5 mb-2'>Partner de Confianza</h4>
                            <p>Interactua con un partner para tu crecimiento global. Desde fusiones y adquisiciones hasta configuracion de entidades colabora con nuestro equipo interno para todas tus necesidades de expansion.</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 text-md-start my-5 p-lg-4">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Integration_81276006e7.svg" alt="" className="img-fluid icons" />
                            <h4 className='mt-5 mb-2'>Integración perfecta</h4>
                            <p>Elimina los silos de datos y reduce el trabajo manual con más de 100 integraciones en todo tu stack tecnológico, desde la contabilidad hasta los gastos.</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 text-md-start my-5 p-lg-4">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Lock_00a2c2eb46.svg" alt="" className="img-fluid icons" />
                            <h4 className='mt-5 mb-2'>Seguridad inigualable</h4>
                            <p>Conecta tu empresa en una única plataforma segura. Nuestra plataforma cumple con el GDPR, SOC2 e ISO 27001.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-cream py-5 text-start">
                <div className="container-bg-cream">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <span>COBERTURA GLOBAL</span>
                                <h3 className='mt-3'>El talento que necesitas esta en todo el mundo ¿Por que no contratar en cualquier parte?</h3>
                                <p className='my-4 mb-5'>Mas de 150 entidades. Mas de 200 expertos legales locales. Global desde el primer dia. Aprovecha la cobertura mas amplia del mercado y comienza a contratar, incorporar y pagar en cualquier lugar.</p>
                                <a href="" className="link-black">Comenzar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-pink py-5'>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-3">
                            <span>CASOS DE CLIENTES</span>
                            <h3>Hemos ayudado a mas de 35,000 empresas a crecer y gestionar equipos globales</h3>
                        </div>
                        <div className="col-12">
                            <div className="row justify-content-center p-3">    
                                <nav>
                                    <div class="nav mini-nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                        <button class="nav-link my-1 me-lg-1 active rounded-4" id="nav-contratacion-tab" data-bs-toggle="tab" data-bs-target="#nav-contratacion" type="button" role="tab" aria-controls="nav-contratacion" aria-selected="true">Contratacion global</button>
                                        <button class="nav-link my-1 rounded-4 me-lg-1" id="nav-global-tab" data-bs-toggle="tab" data-bs-target="#nav-global" type="button" role="tab" aria-controls="nav-global" aria-selected="false">Global Payroll</button>
                                        <button class="nav-link my-1 rounded-4 me-lg-1" id="nav-compliance-tab" data-bs-toggle="tab" data-bs-target="#nav-compliance" type="button" role="tab" aria-controls="nav-compliance" aria-selected="false">Compliance</button>
                                        <button class="nav-link my-1 rounded-4 me-lg-1" id="nav-localizacion-tab" data-bs-toggle="tab" data-bs-target="#nav-localizacion" type="button" role="tab" aria-controls="nav-localizacion" aria-selected="false">Re-localizacion de colaboradores</button>
                                        <button class="nav-link my-1 rounded-4" id="nav-fusiones-tab" data-bs-toggle="tab" data-bs-target="#nav-fusiones" type="button" role="tab" aria-controls="nav-fusiones" aria-selected="false">Fusiones/adquisiciones</button>
                                    </div>
                                </nav>             
                            </div>                            
                        </div>
                    </div>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-contratacion" role="tabpanel" aria-labelledby="nav-contratacion-tab" tabindex="0">
                            <div className="row py-5 my-5">
                                <div className="col-12 col-lg-7 my-3">
                                    <img src="https://deel-website-media-prod.s3.amazonaws.com/Turing_min_9a47ee9e29.jpg" alt="" className="img-fluid" />
                                </div>
                                <div className="col-12 col-lg-5 my-3 card-absolute align-self-center">
                                    <div className="card border-0 rounded-4 p-5 text-start">
                                        <h1>5,000+</h1>
                                        <h3>contratos creados</h3>
                                        <p className="my-4">Turing tiene alrededor de 400 desarrolladores que trabajan para clientes y 150 desarrolladores que trabajan para construir Turing. Pudieron migrar a todos a Deel en días. Ahora utilizan Deel para gestionar, pagar e incorporar a cada desarrollador, en más de 60 países.</p>
                                        <a href="" className="link-outline-white border-0 px-0 m-0">Leer la historia del cliente</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-global" role="tabpanel" aria-labelledby="nav-global-tab" tabindex="0">
                            <div className="row py-5 my-5">
                                <div className="col-12 col-lg-7 my-3">
                                    <img src="https://deel-website-media-prod.s3.amazonaws.com/Change_min_f12c3d11d9.jpg" alt="" className="img-fluid" />
                                </div>
                                <div className="col-12 col-lg-5 my-3 card-absolute align-self-center">
                                    <div className="card border-0 rounded-4 p-5 text-start">
                                        <h1>300+</h1>
                                        <h3>horas ahorradas mensualmente</h3>
                                        <p className="my-4">TDesde que usan Global Payroll de Deel, Change.org ha ahorrado más de 300 horas de administración cada mes. También han aprovechado todos los servicios que ofrece Deel, incluido el uso de EOR para retener a los empleados que necesitan el patrocinio de una visa.</p>
                                        <a href="" className="link-outline-white border-0 px-0 m-0">Leer la historia del cliente</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-compliance" role="tabpanel" aria-labelledby="nav-compliance-tab" tabindex="0">
                            <div className="row py-5 my-5">
                                <div className="col-12 col-lg-7 my-3">
                                    <img src="https://deel-website-media-prod.s3.amazonaws.com/Project44_min_872a8d732d.jpg" alt="" className="img-fluid" />
                                </div>
                                <div className="col-12 col-lg-5 my-3 card-absolute align-self-center">
                                    <div className="card border-0 rounded-4 p-5 text-start">
                                        <h1>500k</h1>
                                        <h3>ahorrados anualmente a través de Deel</h3>
                                        <p className="my-4">Con Deel Shield, Project44 ha podido crear un proceso mucho más fluido y ofrecer a los miembros del equipo una mejor experiencia a través de una sola plataforma para todos. Todas las facturas se crean automáticamente y se pagan a la vez, lo que deja menos espacio para errores y retrasos.</p>
                                        <a href="" className="link-outline-white border-0 px-0 m-0">Leer la historia del cliente</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-localizacion" role="tabpanel" aria-labelledby="nav-localizacion-tab" tabindex="0">
                            <div className="row py-5 my-5">
                                <div className="col-12 col-lg-7 my-3">
                                    <img src="https://deel-website-media-prod.s3.amazonaws.com/Revolut_min_3d351c8eeb.jpg" alt="" className="img-fluid" />
                                </div>
                                <div className="col-12 col-lg-5 my-3 card-absolute align-self-center">
                                    <div className="card border-0 rounded-4 p-5 text-start">
                                        <h1>10+</h1>
                                        <h3>trabajadores reubicados</h3>
                                        <p className="my-4">Introducir nuevos mercados y expandirse a nivel mundial es fundamental para la misión de Revolut y algo que los destaca. Identificaron a Deel como partner que podría ayudarles a encontrar a las personas adecuadas para sus nuevos mercados.</p>
                                        <a href="" className="link-outline-white border-0 px-0 m-0">Leer la historia del cliente</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-fusiones" role="tabpanel" aria-labelledby="nav-fusiones-tab" tabindex="0">
                            <div className="row py-5 my-5">
                                <div className="col-12 col-lg-7 my-3">
                                    <img src="https://deel-website-media-prod.s3.amazonaws.com/EEG_min_b7d5e03593.jpg" alt="" className="img-fluid" />
                                </div>
                                <div className="col-12 col-lg-5 my-3 card-absolute align-self-center">
                                    <div className="card border-0 rounded-4 p-5 text-start">
                                        <h1>48+</h1>
                                        <h3>miembros del equipo incorporados</h3>
                                        <p className="my-4">La facilidad de uso, la sencillez y la eficacia de Deel ayudaron a EEG a renovar por completo su experiencia de onboarding. En lugar de tardar meses para establecer entidades, encontrar proveedores locales de nómina y especialistas laborales locales, Deel convierte la pesadilla logística de las fusiones y adquisiciones en un sueño hecho realidad.</p>
                                        <a href="" className="link-outline-white border-0 px-0 m-0">Leer la historia del cliente</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h2>Empieza a usar Deel en tres sencillos pasos</h2>
                        <div className="col-12 col-lg-6 py-4">
                            <div className="row text-start">
                                <div className="col-12">
                                    <div className="card card-transparent border-0 my-3">
                                        <div className="card-head d-flex">
                                            <h4 className="card-blue me-3">1</h4>
                                            <h4>Programa una llamada</h4>
                                        </div>
                                        <div className="card-body py-0 border-left">
                                            <p>Programa una llamada con nuestros consultores de fuerza laboral global. Te configuraremos una cuenta gratuita lista para satisfacer las necesidades de tu equipo.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card card-transparent border-0 my-3">
                                        <div className="card-head d-flex">
                                            <h4 className="card-blue me-3">2</h4>
                                            <h4>Agrega a tu personal</h4>
                                        </div>
                                        <div className="card-body py-0 border-left">
                                            <p>Desde nuevos empleados hasta tu fuerza laboral existente, incorpora sin esfuerzo con nuestra plataforma de autoservicio.</p>                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card card-transparent border-0 my-3">
                                        <div className="card-head d-flex">
                                            <h4 className="card-blue me-3">3</h4>
                                            <h4>Onboarding dedicado</h4>
                                        </div>
                                        <div className="card-body py-0 border-left">
                                             <p>Desde conocer las leyes locales hasta ayudar a los miembros de tu equipo, nuestro equipo especializado te ayudara a prepararte sin problemas.</p>
                                            <a href="" className="link-black">Comenzar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 py-4 align-self-center">
                            <img src="https://deel-website-media-prod.s3.amazonaws.com/Bar_Graph_Window_9c570c3eda.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-dark py-5">
                <div className="container">
                    <div className="row text-start py-3">
                        <div className="col-1"><img src={g2} alt="" className="img-fluid" /></div>
                        <div className="col-11 align-self-center">
                            <h3>Mira lo que dicen los clientes de Deel</h3>
                            <p>Based on <a href="">3784+ reviews</a></p>
                        </div>
                    </div>
                    <div className="row text-start py-3">
                    <div className="d-flex overflow-x-auto mini-cards-color">
                        <div className="col-12 col-md-4 me-4 my-3">
                            <div className="card card-transparent border-0 d-flex flex-column p-4">
                                <h5>Usuario verificado en telecomunicaciones</h5>
                                <span className='star'>★★★★★</span>
                                <p>Deel ha revolucionado la forma en que gestiono la nómina. Es confiable, fácil de usar y rentable, por lo que también es indispensable...</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 me-4 my-3">
                            <div className="card card-transparent border-0 d-flex flex-column p-4">
                                <h5>Usuario verificado en telecomunicaciones</h5>
                                <span className='star'>★★★★</span>
                                <p>Deel ha sido un cambio radical para mí. Su plataforma es increíblemente fácil de usar. Desde la optimización de mi proceso de nómina hasta...</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 me-4 my-3">
                            <div className="card card-transparent border-0 d-flex flex-column p-4">
                                <h5>Usuario verificado en telecomunicaciones</h5>
                                <span className='star'>★★★★★</span>
                                <p>La plataforma es increíblemente intuitiva, lo que me facilita navegar y hacer las cosas de forma eficiente. Además de eso, su...</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 me-4 my-3">
                            <div className="card card-transparent border-0 d-flex flex-column p-4">
                                <h5>Usuario verificado en telecomunicaciones</h5>
                                <span className='star'>★★★★★</span>
                                <p>Hasta ahora ha sido muy fácil navegar por Deel y todo está diseñado con elegancia para que sea fácil de seguir.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 my-3">
                            <div className="card card-transparent border-0 d-flex flex-column p-4">
                                <h5>Usuario verificado en telecomunicaciones</h5>
                                <span className='star'>★★★★★</span>
                                <p>Es fácil de usar y la seguridad es fabulosa.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 me-4 my-3">
                            <div className="card card-transparent border-0 d-flex flex-column p-4">
                                <h5>Usuario verificado en telecomunicaciones</h5>
                                <span className='star'>★★★★★</span>
                                <p>Deel ha revolucionado la forma en que gestiono la nómina. Es confiable, fácil de usar y rentable, por lo que también es indispensable...</p>
                            </div>
                        </div>
                    </div>                                
                </div>
                </div>
            </section>

        </div>
    )
}

export default Home;